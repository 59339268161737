import React from "react";
import { SEO, PageLayout, HeroElement, ContactForm } from "@bluefin/components";
import { Grid, List } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
            />
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container no-padding"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column computer={12} mobile={16}>
              <h3 className={"header-name"}>Gyro Bar</h3>
              <List
                items={[
                  "Gyro",
                  "Chicken Gyro",
                  "Veggie Gyro",
                  "(Pick One or Mix & Match)",
                ]}
              />
              <h3 className={"header-name"}>AVAILABLE SIDES:</h3>
              <List
                items={[
                  "(Sides Cannot Be Split)",
                  "Greek Salad",
                  "Greek Potatoes",
                  "Drink - (Can Soda or Bottled Water)",
                  "Sandwich + One Side - Call for Pricing",
                  "Sandwich + Two Sides - Call for Pricing",
                  "Sandwich + Three Sides -Call for Pricing",
                ]}
              />
              <h3 className={"header-dessert"}>
                ADD A DESSERT FOR 99¢ MORE PER PERSON
              </h3>
              <h3 className={"header-name"}>RULES:</h3>
              <List
                items={[
                  "We would like 12 hour notice, but call us for late notice or same day order.",
                  "Other menu items require 48 hour notice.",
                  "Minimum of 10 people.",
                  "Call For More Information",
                  "402-558-5623 (40th & Dodge)",
                  "402-504-4976 (114th & Dodge)",
                ]}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column computer={12} mobile={16}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  date,
                  phone,
                  time,
                  guest,
                  method,
                }) =>
                  `Someone has reached out to you via your website catering form: Name: ${name}, Email: ${email}, Phone: ${phone},  Event Date: ${date}, Event Time: ${time}, Total Guest: ${guest}, How will you receive your food? (Required): ${method}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "date",
                    displayLabel: "Event Date",
                    placeholder: "Event Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "time",
                    displayLabel: "Event Time",
                    placeholder: "Event Time",
                    type: "input",
                    inputType: "datetime",
                    required: true,
                  },
                  {
                    label: "guest",
                    displayLabel: "Number Of Guest",
                    placeholder: "Number Of Guest",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "method",
                    displayLabel: "How will you receive your food? (Required)",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Pickup", value: "Pickup" },
                      { label: "Delivery", value: "Delivery" },
                    ],
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={"Contact Us"}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Catering" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
